/* theme customize Override css======> */

:root{
  --primary:#000000!important;
  --lightPrimary:#78A3001a!important;
  --secondary:#11d50aa8!important;
  --accent: #6a91000f!important;
  --hover:#9bd203be!important;
  --text:#72849a!important;
  --active-list:#455560!important;
  --danger-alt:#ff6b72!important;
}
.bg-primary{background-color: var(--primary)!important;}
.text-primary{color: var(--primary)!important;}a.text-primary:focus,a.text-primary:hover{color: var(--primary)!important;}
.bg-custom-1{background-color: #eaf4fe!important;transition: all 0.30s ease;}
.bg-primary{background-color: var(--primary)!important}a.bg-primary:focus,a.bg-primary:hover,button.bg-primary:focus,button.bg-primary:hover{background-color:#618402!important}

.ant-menu-light .ant-menu-item-selected{color: var(--primary)!important;}
.ant-menu-light .ant-menu-item-selected{background-color: var(--lightPrimary);}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after{border-color: var(--primary);}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{color: var(--primary)!important;}
.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){color: var(--primary)!important;}
.ant-btn-primary{background-color: var(--primary)!important;}
.ant-ribbon{background-color: var(--primary)!important;}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover{background-color: #79a402!important;}
.ant-btn-primary:not(:disabled):hover{background-color: #79a402a8!important;}
.ant-btn-default:not(:disabled):hover{color: white;border-color: var(--primary)!important;}
.border-btn:not(:disabled):hover{color: var(--primary)!important;border-color: var(--primary)!important;}
.ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus, .ant-input-number-focused{border-color: var(--primary)!important;}
.text-editor .ql-container:hover,
.text-editor .ql-container:focus-within{border:1px solid var(--primary)!important;border-radius: 0 0 10px 10px;}
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke{stroke: var(--primary)!important;}
.ql-toolbar.ql-snow .ql-picker-label:hover{color: var(--primary)!important;}
.ql-snow.ql-toolbar button:hover > *{stroke: var(--primary)!important;}
.ant-spin .ant-spin-dot-item{background-color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-today-btn{color: var(--primary);}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover{color: var(--hover);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border-color: var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{background-color: var(--primary);}
.more-btn{background-color: transparent;color: var(--primary);border: 1px solid var(--primary);}
.more-btn:hover{border-color: transparent;}
.ant-pagination .ant-pagination-item-active{background-color: var(--primary);}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--accent);}
.ant-btn-dangerous:hover{background-color: var(--danger-alt);border: none!important;color: white;}
.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector{border: 1px solid var(--primary)!important;}
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{border-color: var(--primary)!important;}
h5.input_img_text > span.text-info{color: var(--primary)!important;}
.ant-spin {color: var(--primary);}
.border-button{
  background-color: transparent;color: var(--primary);border: 1px solid var(--primary);
}
.border-button:hover{background-color: var(--primary);}
.edit-button{background-color: var(--primary);color: white;}
.edit-button:hover{background-color:transparent!important;color: var(--primary)!important;border: 1px solid;}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{background-color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before{background-color: var(--accent);}


/* ========================> */

/* --------Global Css========> */
.bg-cancel{background-color: var(--danger-alt)!important;}
ul{padding: 0;}
ul li{list-style-type: none;}

/* new class css */
.gap-1{gap: 4px;}
.gap-2{gap: 8px;}
.gap-3{gap: 12px;}
.gap-4{gap: 16px;}
.gap-5{gap: 20px;}
.justify-content-evenly{justify-content: space-evenly;}
.cursor-pointer{cursor: pointer;}

/* checkbox */

.check_box {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt {border: 2px solid #e6ebf1;width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt:hover {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt.active{background-color: var(--primary);border-color: var(--primary);}


/* ==============> */
.left-0{left: 0;}
.top-0{top: 0;}
.shadow{box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05)!important;}
.shadow-sm{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.shadow-md{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.show{display: flex;}
.hide{visibility: hidden!important;opacity:0;transition:all 0.30s ease;}
.disabled-btn{cursor: not-allowed;border-color: #e6ebf1;color: rgba(69, 85, 96, 0.25);background-color: rgba(69, 85, 96, 0.04);box-shadow: none;}
.scrollbar_smooth{overflow-y: scroll;overflow-x: hidden;}
.scrollbar_smooth::-webkit-scrollbar{width: 6px;background-color: transparent;}
.scrollbar_smooth::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.scrollbar_smooth::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}
/* form sec */

/* .form-sec .ant-form-item-control > div:nth-child(2){
    position: absolute;
    bottom: -22px
} */
.no_data_box{border-radius: 10px;position: relative;}
.no_data_box div{color: rgba(128, 128, 128, 0.536);}
.loading{width: 100%;height: 100%;position: absolute;backdrop-filter: blur(1px);top:0;left: 0;}
/* ---------------------- */

.login-logo{height: 80px;overflow: hidden;width: 80px;}
.site_logo{object-fit: contain;}

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {border: 1px dashed gray; height: 150px;cursor: pointer;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right{width: 50px;height: 50px;display: flex;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right{filter: grayscale(1);opacity: 0.6;}

.file_upload_sec .img_icon_left{right: -50px;transition: all 0.30s ease;}
.file_upload_sec .img_icon_right{left: -50px;transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_left{right: -20px;transform: rotate(-20deg);transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_right{left: -20px;transform: rotate(20deg);transition: all 0.30s ease;}

.file_upload_sec .img_icon{z-index: 1;filter: hue-rotate(232deg)}
.file_upload_sec .img_icon img{width: 100%;height: 100%;object-fit: contain; object-position: center;}
.file_upload_sec .uploaded_img{object-fit: cover;object-position: center;}

.file_upload_input{width: 100%;height: 100%;opacity: 0;z-index: 2;cursor: pointer;position: absolute;}
.img_delete_sec{top:0;left: 0;background-color: rgba(174, 173, 173, 0.41);}
.delete_btn{border: 1px solid var(--danger-alt);display: flex;align-items: center;justify-content: center;width: 40px; height: 40px;border-radius: 50%;background-color: var(--danger-alt);transition:all 0.30s ease;}
.delete_btn:hover{scale: 1.2;transition:all 0.30s ease;}
.delete-btn{padding:0 20px;border: 1px solid var(--danger-alt)!important;display: flex;align-items: center;justify-content: center;border-radius: 10px!important;background-color: var(--danger-alt);transition:all 0.30s ease;color: white;}
.delete-btn:hover{background-color: transparent;color: var(--danger-alt)!important;border-color: var(--danger-alt)!important;}
.active-btn{border-radius: 10px;padding: 0 20px;color: #08979c;background: #e6fffb;border:1px solid #87e8de;}
.in-active-btn{border-radius: 10px;padding: 0 20px;color: #cf1322;background: #fff1f0;border:1px solid #ffa39e;}
.active-btn:hover,
.in-active-btn:hover{background-color: transparent;}



/* =====================> */

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {
  border: 1px dashed gray;
  height: 150px;
  cursor: pointer;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right {
  width: 50px;
  height: 50px;
  display: flex;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right {
  filter: grayscale(1);
  opacity: 0.6;
}

.file_upload_sec .img_icon_left {
  right: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec .img_icon_right {
  left: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_left {
  right: -20px;
  transform: rotate(-20deg);
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_right {
  left: -20px;
  transform: rotate(20deg);
  transition: all 0.3s ease;
}

.file_upload_sec .img_icon {
  z-index: 1;
}
.file_upload_sec .img_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.file_upload_sec .uploaded_img {
  object-fit: cover;
  object-position: center;
}

.file_upload_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.img_delete_sec {
  top: 0;
  left: 0;
  background-color: rgba(174, 173, 173, 0.41);
}
/* =====================> */

.tasks_card {
  background-color: #fafafb;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}
.tasks_card .img_sec {
  width: 45%;
  overflow: hidden;
  border-radius: 5px;
}
.tasks_card .list_sec {
  width: -webkit-fill-available;
}
.tasks_card .img_sec img {
  object-fit: cover;
  object-position: center;
}
.tasks_card .challenge_card > div {
  height: 136px;
}
.tasks_card .challenge_card .list_sec {
  overflow-y: scroll;
}
/* .tasks_card::before{content: '';width: 80px;height: 80px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-three);}
.tasks_card::after{content: '';width: 90px;height: 90px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-two);z-index: 1;} */

.tasks_card .checkbox {
  border: 2px solid transparent;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}
.tasks_card .checkbox.checked {
  background-color: var(--primary);
}
.tasks_card .checkbox:not(.checked) {
  border-color: var(--primary);
  background-color: transparent;
}
.tasks_card .checkbox > span {
  scale: 0.8;
}
.tasks_card li .challenge_name {
  color: var(--text);
}
.tasks_card .challenge_card_heading {
  color: var(--primary);
}
.quill > .ql-container {
  border-radius: 0 0 10px 10px;
  height: 200px;
  max-height: 250px;
}

/* <---------media box------> */
.media_box {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  margin: 10px;
}
.media_box img {
  object-fit: cover;
  object-position: center;
}
.media_box.pdf_sec .icon img {
  object-fit: contain;
  object-position: center;
}
.media_box .name_sec {
  background-color: var(--primary);
  color: white;
  overflow: hidden;
  white-space: nowrap;
}
.media_box .name {
  overflow: hidden;
}

/* select custom components */

.select_custom {
  position: relative;
}
.select_custom .list_sec {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 2;
  max-height: 240px;
  overflow-y: scroll;
}
.select_custom .list_sec::-webkit-scrollbar {
  width: 8px;
}
.select_custom .list_sec::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.select_custom .list {
  cursor: pointer;
  background-color: transparent;
  margin: 4px 0;
}
.select_custom .list:hover {
  background-color: #fafafb;
}
.select_custom .checked_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.select_custom .dropdown .selected {
  background-color: #95b7ff13;
}

/* ----delete popup---- */

.delete_popup {
  background-color: rgba(0, 0, 0, 0.255);
  z-index: 1000;
}
.delete_popup .popup {
  border-radius: 5px;
  width: 300px;
}
.delete_popup .popup .dialogue {
  line-height: 20px;
}
.delete_popup .popup .del_btn {
  background-color: var(--danger-alt);
}
.delete_popup .popup .del_btn:focus-visible {
  outline: 4px solid #ff848ab1;
}
.delete_popup .popup .del_btn:hover {
  border: 1px solid #ff757c !important;
}

/* full screen loading-----> */
.full_screen_loading {
  background-color: rgba(0, 0, 0, 0.255);
  z-index: 1000;
}

/* coach_profile_userlist */

.coach_profile_userlist {
  height: 325px;
}

/* media library */

.media_container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.file-man-box {
  overflow: hidden;
  padding: 10px;
  border: 1px solid #e3eaef;
  border-radius: 5px;
  position: relative;
  height: 144px;
  max-width: 144px;
  min-width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.file-man-box .file-img-box {
  text-align: center;
}
.file-man-box .file-img-box img {
  height: 64px;
}

.file-man-box:hover {
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}
.file-man-box h5 {
  font-size: 13px;
  margin-bottom: -8px;
}
.file-man-box.affirmation {
  padding: 0;
  overflow: hidden;
}
.file-man-box.affirmation .file-img-box,
.file-man-box.affirmation .file-img-box img {
  width: 100%;
  height: 100%;
}
.file-man-box.affirmation .file-img-box img {
  object-fit: cover;
  object-position: center;
}

.file-img-box:not(.file-img-box.image) img{
  object-fit: contain;
}

.file-man-box .action {
  background-color: #00000070;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.file-man-box:hover .action {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.file-man-box .action > div {
  height: 35px;
  width: 35px;
  background-color: #fafafb;
  border-radius: 3px;
}
.file-man-box .action > div.del {
  background-color: var(--danger-alt);
  color: white;
}
.file-man-box .action > div:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.file-man-box .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: white;
}
.media_header .ant-upload-list {
  display: none;
}
.uploading_box {
  border: 1px dashed rgba(128, 128, 128, 0.585);
}
.uploading_text {
  color: gray;
  font-size: 12px;
}

/* view media */
.media_drawer .ant-drawer-body {
  padding: 12px !important;
}
.media_draw_box .media_draw_box_preview {
  width: 100%;
  overflow: hidden;
  height: 150px;
  border-radius: 5px;
}
.media_draw_box .media_draw_box_preview img {
  object-fit: cover;
  object-position: center;
}
.media_draw_box .details_sec table div {
  padding: 3px 0;
  color: gray;
}
.media_draw_box .details_sec table tr td:last-child div {
  padding-left: 30px;
  color: var(--primary);
}

.media_header_sec .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  background-color: white;
}

/* checkbox */

.check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt {
  border: 2px solid #e6ebf1;
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt:hover {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Query Search Filter */
.table-filter-list {
  padding: 7px 20px;
  border-radius: 10px;
}
.table-filter-list:hover {
  background-color: #f0f7ff;
}
.filter_list_sec .filter_list {
  max-height: 165px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(128, 128, 128, 0.311);
}
.filter_list_sec .filter_list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.filter_list_sec .filter_list::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

/* Active Inactive button */

.activeBtn {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.activeBtn:hover {
  color: #08979c !important;
  background: #e6fffb !important;
  border-color: #87e8de !important;
}
.inActiveBtn {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.inActiveBtn:hover {
  background: #fff2e8 !important;
  color: #d4380d !important;
}



.media-selector {
  border: 1px dashed rgb(211, 211, 211);
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}
.media-selector input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
}
.media-selector .media {
  top: 0;
  left: 0;
}
.media-selector .media .thumbnai {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px dashed rgb(211, 211, 211);
  overflow: hidden;
  padding: 2px;
}
.media-selector .media .thumbnai img{border-radius: 3px;}
.media-selector .filename {
  height: 50px;
  width: calc(97% - 50px);
  border-radius: 3px;
}
.media-selector .filename .name {
  width: calc(99% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgb(185, 185, 185);
}
.media-selector .close-btn {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--danger-alt);
  color: white;
  font-size: 12px;
  z-index: 3;
}
.upload-media-modal .radio-item{border-radius: 5px;white-space: nowrap;background-color: var(--light);}




/* *****OPEN HOUR*** */
.openingHour-Width{width: 35%; color: #000; font-weight: 600;}
.openingHour-Width .icon{font-size: 14px;}
.openingDate-width{width: 65%; color: #5a5a5a; font-size: 16px;}
/* *****OPEN HOUR END*** */

/* Wishlist  */
.image-container {
  position: relative;
}
.wishlist-icon {
  position: absolute;
  top: 1px;
  right: 3px;
  z-index: 1;
}
.category-name {
  margin: 0;
  font-weight: bold;
  text-align: center;
  color: #333; /* Adjust the color as needed */
}
.image-container1 img {
  width: 100%;
  height: auto;
  border-radius: 50%; /* Make the image round */
}
